import React, { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Axios, ExternalAxios} from "./axiosInstances";
import axios  from "axios"
import "./templates.css";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { removeCustomerList,removeCheckedCustomerList } from "../features/selectedCustomerListSlice";

function Templates() {

  const fileInputRef = useRef(null);

  const [templatesData, setTemplatesData] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [variablesHeader, setVariablesHeader] = useState({});
  // const [templateCategory,setTemplateCategory]=useState()
  // const [templateCategoryList,setTemplateCategoryList]=useState()
  const [addTemplateHeaderContent,setAddTemplateHeaderContent]=useState('')
  const [addTemplateBodyContent,setAddTemplateBodyContent]=useState('')
  const textareaRef = useRef(null);
  const textareaRefHeader = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [websiteName, setWebsiteName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  
  const [templateName,setTemplateName ] = useState('');
  const [headerMediaType,setHeaderMediaType]=useState('');
  const [headerDocumentLink,setHeaderDocumentLink]=useState('');
  const [headerDocumentName,setHeaderDocumentName]=useState('');
  const [headerDocumentupload,setHeaderDocumentUpload]=useState('');
  const [headerDocumentId,setHeaderDocumentId]=useState('');

  const [headerImageLink,setHeaderImageLink]=useState('');
  const [headerImageupload,setHeaderImageUpload]=useState('');
  const [headerImageId,setHeaderImageId]=useState('');

  const [whatsappAccessToken,setWhatsappAccessToken]=useState('');
  const [whatsappApiEndpoint,setWhatsappApiEndpoint]=useState('');

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const selectedRows = useSelector((state) => state.selectedCustomerList.customerList);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  const templatesDataFetch = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.get(
        `/templateviewset/list_templates/?company_id=${userInfo.data.iCompanyID}`,
        // `https://crm.suvarnakarsoftware.com/api/v1/templateviewset/list_templates/?category_name=marketing&company_id=${userInfo.data.iCompanyID}`,
        config
      );

      if (res.data.template_list) {
        setTemplatesData(res?.data.template_list);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const handleOpenModal = () => {
    setShowModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleCloseModal = () => {
    setIsAdding(false)
    setShowModal(false);
    document.body.classList.remove("overflow");
  };

  const handleAddTemplateOpenModal = () => {
    setShowAddTemplateModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleAddTemplateCloseModal = () => {
    setAddTemplateHeaderContent('');
    setHeaderMediaType('');
    setTemplateName('');
    setAddTemplateBodyContent('');
    setShowAddTemplateModal(false);
    document.body.classList.remove("overflow");
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
  };

  const adjustTextareaHeightHeader = () => {
    if (textareaRefHeader.current) {
    const textarea = textareaRefHeader.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleAddTemplateSelection = () =>{
    handleAddTemplateOpenModal();
  }
  const handleTemplateSelection = (templateData) => {
    if (selectedRows == undefined || selectedRows == null) {
      toast.warning("Not Selected Any Customers.");
    }
    else{
      handleOpenModal();
      setSelectedTemplate(templateData);
      setHeaderMediaType(templateData.header_type)
    }
  };

  const handleTemplateChange = (template) => {
    adjustTextareaHeightHeader();
    adjustTextareaHeight();
    const body_content=template.content
    const total_content=template.header_content+body_content
    const regex = /{{\s*([^{}\s]+)\s*}}/g;
    const matches = [...total_content.matchAll(regex)];
    const vars = {};

    matches.forEach((match) => {
      const variable = match[1].trim();

      if (variable == "company_name") {
        vars[variable] = userInfo&&userInfo.data&&userInfo.data.vCompanyName;
      }
      else if (variable !== "customer_name") {
        vars[variable] = variable;
      }
    });
    setVariables(vars);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariables((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const renderTemplateHeader = () => {
    adjustTextareaHeightHeader()
    let renderedTemplate = selectedTemplate.header_content;
    for (const [key, value] of Object.entries(variables)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue="{{"+value+"}}"
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };
  const renderTemplate = () => {
    adjustTextareaHeight()
    let renderedTemplate = selectedTemplate.content;
    for (const [key, value] of Object.entries(variables)) {
      const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");
      const finalvalue="{{"+value+"}}"
      renderedTemplate = renderedTemplate.replace(regex, finalvalue);
    }
    return renderedTemplate;
  };

  const handleTemplateSave = (e) => {
    e.preventDefault();
    if (headerMediaType === 'document' && !headerDocumentLink && !headerDocumentupload) {
      toast.warning("Please provide a document link or upload a file.");
      return;
    }
    else if (headerMediaType === 'image' && !headerImageLink && !headerImageupload) {
      toast.warning("Please provide an image link or upload a file.");
      return;
    }
    const newContentHeader = renderTemplateHeader();
    const regexHeader = /{{\s*(.+?)\s*}}/g;
    const matchesHeader = [...newContentHeader.matchAll(regexHeader)];
    let varsHeader = [];
    matchesHeader.forEach((match) => {
      const variable = match[1].trim();
        varsHeader=[...varsHeader,variable];
    });

    const newContent = renderTemplate();
    const regex = /{{\s*(.+?)\s*}}/g;
    const matches = [...newContent.matchAll(regex)];
    let vars = [];
    matches.forEach((match) => {
      const variable = match[1].trim();
        vars=[...vars,variable];
    });

    const buttons = [
      {
        type: 'url',
        url: websiteURL,
        text: websiteName
      }
    ]
    handleSendMessage(vars,varsHeader,buttons)
    setIsAdding(false);
    setWebsiteName('');
    setWebsiteURL('');
    handleCloseModal()
  };

  const handleSendMessage = async (variableList,variableListHeader,buttons) => {    
      try {
        const combinedData = {
          users: selectedRows,
          template_slug:selectedTemplate.slug,
          template_name:selectedTemplate.template_name,
          company_id:userInfo.data.iCompanyID,
          body_vars:variableList,
          header_vars:variableListHeader,
          buttons:buttons,
          document_link: headerMediaType === 'document' ? headerDocumentLink : null,
          document_id: headerMediaType === 'document' ? headerDocumentId : null,
          document_name: headerMediaType === 'document' ? headerDocumentName : null,
          image_link: headerMediaType === 'image' ? headerImageLink : null,
          image_id: headerMediaType === 'image' ? headerImageId : null,
        };

        const config = {
          headers: {
            "Content-type": "application/json",
            "Accept":"application/json",
          },
        };

        const res = await Axios.post(
          "/whatsappviewset/send_whatsapp_message/",
          combinedData,
          config
        );
        
          Swal.fire({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
            })
            
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        console.log("Error:", error.message);
      }
      finally{
        dispatch(removeCustomerList())
        dispatch(removeCheckedCustomerList())
      }
    
  };

  // const templateCategoryData= async () => {
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     };

  //     const res = await axios.get(
  //       `https://crm.suvarnakarsoftware.com/api/v1/templateviewset/list_template_category/?company_id=${userInfo.data.iCompanyID}`,
  //       config
  //     );

  //     if (res.data && res.data.success && res.data.categories) {
  //       setTemplateCategoryList(res.data.categories.map(item => item.category));
  //     } else {
  //       setTemplateCategoryList([]);
  //     }

  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Response Error:", error.response.data);
  //       console.error("Status Code:", error.response.status);
  //     } else if (error.request) {
  //       console.error("Request Error:", error.request);
  //     } else {
  //       console.error("Error:", error.message);
  //     }
  //   }
  // };


  const AddTemplateSubmitHandler = (e) => {
    e.preventDefault();
    try{
    addTemplateData({
      company_id:userInfo.data.iCompanyID,
      template_name:templateName,
      slug:templateName,
      // category_name:templateCategory,
      header_type:headerMediaType,
      header_content:addTemplateHeaderContent,
      content:addTemplateBodyContent,
    });
    handleAddTemplateCloseModal()


  } catch (error) {
    console.error("Error:", error);
    toast.error('Failed to submit the form. Please try again.');
  }
  };

  const addTemplateData= async (combinedData) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await Axios.post(
        `/templateviewset/add_template/`,
        combinedData,
        config
      );

      if(res.status&&res.data.success){
        toast.success(res.data.message)
        await templatesDataFetch()
      } else{
        toast.error(res.data.error)
      }

      // setTemplateCategory("");
      setTemplateName("");
      setHeaderMediaType("");
      setAddTemplateHeaderContent("");
      setAddTemplateBodyContent("");
      setTemplateName("");

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  
  const handleAddTemplateHeaderContentChange = (e) => {
    setAddTemplateHeaderContent(e.target.value);
  };
  const handleAddTemplateBodyContentChange = (e) => {
    setAddTemplateBodyContent(e.target.value);
  };


  const handleDocumentFileChange = async (e) => {
    try {

      const file = e.target.files[0];
      setHeaderDocumentUpload(e.target.files[0]);
      setHeaderDocumentLink('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp'); 

      const config = {
        headers: {
          Authorization: `Bearer ${whatsappAccessToken}`,
          // 'Content-Type': 'multipart/form-data',
        },
      };
      const whatsappUploadUrl=whatsappApiEndpoint.replace('/messages', '/media');
      const res = await axios.post(
        whatsappUploadUrl,
        formData,
        config
      );

      if(res.data&&res.data.id){
        setHeaderDocumentId(res.data.id);
        toast.success(res.data.message)
      } else{
        toast.error(res.data.error)
      }
      
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Handle link input
  const handleDocumentLinkChange = (e) => {
    setHeaderDocumentLink(e.target.value);
    setHeaderDocumentUpload(null); 
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    } 
  };

  const handleImageFileChange = async (e) => {
    try {

      const file = e.target.files[0];
      setHeaderImageUpload(e.target.files[0]);
      setHeaderImageLink('');  

      const formData = new FormData();
      formData.append('file', file);
      formData.append('messaging_product', 'whatsapp'); 

      const config = {
        headers: {
          Authorization: `Bearer ${whatsappAccessToken}`,
          // 'Content-Type': 'multipart/form-data',
        },
      };
      const whatsappUploadUrl=whatsappApiEndpoint.replace('/messages', '/media');
      const res = await axios.post(
        whatsappUploadUrl,
        formData,
        config
      );

      if(res.data&&res.data.id){
        setHeaderImageId(res.data.id);
        toast.success(res.data.message)
      } else{
        toast.error(res.data.error)
      }
      
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Handle link input
  const handleImageLinkChange = (e) => {
    setHeaderImageLink(e.target.value);
    setHeaderImageUpload(null); 
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    } 
  };

  const ConnectToWhatsappDataGet = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await Axios.get(
        `/whatsappviewset/get_whatsapp_token/${userInfo.data.iCompanyID}`,
        config
      );
      if(data){
        setWhatsappAccessToken(data.access_token);
        setWhatsappApiEndpoint(data.u_id);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };



  useEffect(() => {
    if(!token){
      navigate('/error')
      return;
    }
    
    if(!templatesData){
      templatesDataFetch()
      // templateCategoryData()
    }

    if(!whatsappAccessToken){
      ConnectToWhatsappDataGet()
    }
    if (textareaRef.current) {
      adjustTextareaHeight();
      adjustTextareaHeightHeader();
    }
    if (showModal) {
      handleTemplateChange(selectedTemplate);
    }

    const handleClickOutsideModal = (event) => {
      if (event.target.className === "modal") {
        handleCloseModal();
        handleAddTemplateCloseModal();
        document.body.classList.remove("overflow");
      }
    };

    window.addEventListener("click", handleClickOutsideModal);

    return () => {
      window.removeEventListener("click", handleClickOutsideModal);
    };
  }, [selectedTemplate,selectedTemplate.content,showModal,showAddTemplateModal,selectedRows,dispatch,navigate,token]);

  

  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
        <SideBar />

        <main className="page-content bg-gray-50 dark:bg-slate-800">
          <Header toggleSidebar={handleToggleSidebar}/>
          <div className="container-fluid relative px-3">
            <div className="layout-specing">
              <div className="md:flex justify-between items-center">
                <div>
                  <h5 className="text-lg font-semibold">Templates</h5>
                </div>
                <div className="flex justify-end">
                <button
                  onClick={() => {
                  handleAddTemplateSelection();
                  }}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md 
                    bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                >
                  Add New Template
                </button>
                </div>
              </div>
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
                {templatesData?templatesData.map((template) => (
                  <div
                    className={`relative flex flex-col justify-between rounded-md shadow dark:shadow-gray-700 overflow-hidden min-h-[300px]  ${
                      selectedTemplate === template.id
                        ? " border-4  !important"
                        : "bg-white dark:bg-slate-900"
                    }`}
                  >

                    <div className="content p-6 flex-grow">
                      <h6 className="mb-4 text-15 font-bold">
                        {template.template_name}
                      </h6>
                  <SimpleBar style={{ maxHeight: "200px"}} autoHide={false}>

                      <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.header_content}</pre>
                      <pre className="mb-2 me-3 whitespace-pre-wrap break-words leading-normal" style={{ whiteSpace: 'pre-wrap' }}>{template.content}</pre>
                    
                      <div className="variables mb-4">
                      </div>
                    </SimpleBar>  
                    </div>
                    <div className="px-6 pb-6 relative bottom-0 left-0">
                        {
                          !isEmptyObject(selectedRows)&&
                        <button
                          onClick={() => {
                            handleTemplateSelection(template);
                          }}
                          className={`py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md  ${
                            selectedTemplate === template.id
                              ? " bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700"
                              : "bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                          }`}
                        >
                          Select Template
                        </button>
                        }
                      {showModal && (
                        <div className="modal">
                          <div className="modal-content">
                            <span
                              className="close -mt-5"
                              onClick={handleCloseModal}
                            >
                              &times;
                            </span>
                            <div className="section-title ">
                              <h4 className="text-1xl font-semibold uppercase mb-3">
                                {selectedTemplate.template_name}
                              </h4>

                            <SimpleBar style={{ maxHeight: "50vh" }} autoHide={false}>
                              <div className="w-full p-4">
                                <form onSubmit={handleTemplateSave}>
                                {selectedTemplate.header_type=="text"&&
                                <>
                                <label className=" font-medium">Header:</label>
                                    <textarea
                                    ref={textareaRefHeader}
                                    className="w-full p-4 my-3 bg-gray-100 dark:bg-gray-800 dark:text-white rounded-md border border-gray-300 dark:border-gray-700 overflow-hidden resize-none"
                                    onInput={adjustTextareaHeightHeader}
                                    value={renderTemplateHeader()}
                                    readOnly
                                    />
                                </>}
                                  
                                  
                                      {/* {!selectedTemplate.header_content&&<select
                                        name="media"
                                        className="form-select form-input mb-3 w-full py-2 px-3 h-10 bg-transparent  dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                        onChange={(e) => setHeaderMediaType(e.target.value)}
                                        value={headerMediaType}
                                      >
                                        <option value="none" selected>
                                          None
                                        </option>
                                        <option value="document" selected>
                                          Document
                                        </option>
                                        
                                      </select>} */}
                                    
                                  {selectedTemplate.header_type=="document"&&
                                  <>
                                      <label htmlFor="media" className="form-label font-medium">
                                        Header Document:
                                      </label>
                                  <div className="flex items-center  space-x-4 w-full">
                                    <div className=" flex-grow">
                                      {/* <label htmlFor="document_link" className="form-label font-medium">
                                        Document Link:
                                      </label> */}
                                      <div className="">
                                        <input
                                          type="text"
                                          className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                          name="document_link"
                                          id="document_link"
                                          onChange={handleDocumentLinkChange}
                                          value={headerDocumentLink}
                                          placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                        />
                                      </div>
                                    </div>
                                    <div className="text-gray-500 font-medium px-2 whitespace-nowrap">OR</div>

                                    <div className="flex-shrink-0">
                                    <label
                                      htmlFor="document_upload"
                                      className="w-full  mb-3 mt-2 rounded-md   py-2 px-3 h-10 border border-indigo-600 text-indigo-600 hover:border-indigo-400 hover:bg-indigo-300 hover:text-indigo-400 cursor-pointer flex items-center justify-center  whitespace-nowrap"
                                    >
                                      Choose file
                                    </label>
                                      <div className=" text-indigo-600">
                                        {headerDocumentupload ? "("+headerDocumentupload.name+")" : ""}
                                      </div>
                                      <div className="">
                                      <input type="file" 
                                      onChange={handleDocumentFileChange} 
                                      ref={fileInputRef}                                      
                                      accept=".pdf"  
                                      name="document_upload"
                                      id="document_upload"
                                      className="hidden"/>
                                      </div>
                                    </div>
                                  </div>  
                                  <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                    <div className="xl:col-span-12 md:col-span-12 mb-3">
                                      <label htmlFor="document_link" className="form-label font-medium">
                                        Document Name:
                                      </label>
                                      <div className="">
                                        <input
                                          type="text"
                                          className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                          name="document_name"
                                          id="document_name"
                                          onChange={(e) => setHeaderDocumentName(e.target.value)}
                                          value={headerDocumentName}
                                          placeholder="example.pdf"
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>  
                                  </>
                                  }                                
                                  {selectedTemplate.header_type=="image"&&
                                  <>
                                      <label htmlFor="media" className="form-label font-medium">
                                        Header Image:
                                      </label>
                                  <div className="flex items-center  space-x-4 w-full">
                                    <div className=" flex-grow">
                                      {/* <label htmlFor="document_link" className="form-label font-medium">
                                        Document Link:
                                      </label> */}
                                      <div className="">
                                        <input
                                          type="text"
                                          className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                          name="image_link"
                                          id="image_link" 
                                          onChange={handleImageLinkChange}
                                          value={headerImageLink}
                                          placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                        />
                                      </div>
                                    </div>
                                    <div className="text-gray-500 font-medium px-2 whitespace-nowrap">OR</div>

                                    <div className="flex-shrink-0">
                                    <label
                                      htmlFor="image_upload"
                                      className="w-full  mb-3 mt-2 rounded-md   py-2 px-3 h-10 border border-indigo-600 text-indigo-600 hover:border-indigo-400 hover:bg-indigo-300 hover:text-indigo-400 cursor-pointer flex items-center justify-center  whitespace-nowrap"
                                    >
                                      Choose file
                                    </label>
                                      <div className=" text-indigo-600">
                                        {headerImageupload ? "("+headerImageupload.name+")" : ""}
                                      </div>
                                      <div className="">
                                      <input type="file" 
                                      onChange={handleImageFileChange} 
                                      ref={fileInputRef}                                      
                                      accept="image/*"  
                                      name="image_upload"
                                      id="image_upload"
                                      className="hidden"/>
                                      </div>
                                    </div>
                                  </div>  
                                  </>
                                  }                                


                                  
                                  <label className=" font-medium">Body:</label>
                                  <textarea
                                    ref={textareaRef}
                                    className="w-full p-4 my-3 bg-gray-100 dark:bg-gray-800 dark:text-white rounded-md border border-gray-300 dark:border-gray-700 overflow-hidden resize-none"
                                    onInput={adjustTextareaHeight}
                                    value={renderTemplate()}
                                    readOnly
                                  />
                                  <div className="py-3">
                                  {Object.keys(variablesHeader).map((key) => (
                                    <div key={key} className="py-2" >
                                      <label htmlFor={key}>
                                        {key.charAt(0).toUpperCase() +
                                          key.slice(1)}
                                      </label>
                                      <div className="">
                                        <input
                                          type="text"
                                          className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                          name={key}
                                          id={key}
                                          value={variablesHeader[key]}
                                          onChange={handleInputChange}
                                          required
                                        />
                                      </div>

                                    </div>
                                  ))}
                                  {Object.keys(variables).map((key) => (
                                    <div key={key} className="py-2">
                                      <label htmlFor={key}>
                                        {key.charAt(0).toUpperCase() +
                                          key.slice(1)}
                                      </label>
                                      <div className="">
                                        <input
                                          type="text"
                                          className="form-input w-full mb-3 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                          name={key}
                                          id={key}
                                          value={variables[key]}
                                          onChange={handleInputChange}
                                          required
                                        />
                                      </div>

                                    </div>
                                  ))}
                                  </div>
                                  <div className="flex flex-col items-end space-y-4">
                                  {/* <div className="flex justify-end">
                                    <button 
                                      onClick={handleAddButtonClick} 
                                      disabled={isAdding}
                                      className={`py-2 px-4 rounded-lg font-semibold  text-black border-2  border-black  ${
                                        isAdding ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-100 hover:bg-gray-400 '
                                      } transition-colors duration-300`}
                              
                                    >
                                      Add Button
                                    </button>
                                    </div> */}
                                
                                  {isAdding && (
                                  <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                    <div className="xl:col-span-6 md:col-span-6">
                                    <label htmlFor="website_name" className="">
                                      Website Name
                                    </label>
                                    <input
                                      type="text"
                                      id="website_name"
                                      name="website_name"
                                      value={websiteName}
                                      onChange={(e) => setWebsiteName(e.target.value)}
                                      className="form-input w-full py-2 px-3 h-10 mb-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                      placeholder="Enter website name"
                                    />
                                  </div>
                        
                                  <div className="xl:col-span-6 md:col-span-6">
                                    <label htmlFor="website_url" className="">
                                      Website URL
                                    </label>
                                    <input
                                      type="text"
                                      id="website_url"
                                      name="website_url"
                                      value={websiteURL}
                                      onChange={(e) => setWebsiteURL(e.target.value)}
                                      className="form-input w-full py-2 px-3 mb-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                      placeholder="Enter website URL"
                                    />
                                  </div>
                                  </div>
                                    )}  
                                    <div className="flex justify-start">                              
                                  <button
                                    type="submit"
                                    className="py-2 px-5 font-semibold tracking-wide border border-indigo-600 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700 transition-colors duration-300"                                  >
                                    Send Message
                                  </button>
                                  </div>
                                  </div>
                                </form>
                              </div>
                            </SimpleBar>
                            </div>
                          </div>
                        </div>
                      )}
                      {showAddTemplateModal && (
                        <div className="modal">
                          <div className="modal-content modal-content-wide">
                            <span
                              className="close -mt-5"
                              onClick={handleAddTemplateCloseModal}
                            >
                              &times;
                            </span>
                            <div className="section-title ">
                              <h4 className="text-1xl font-semibold uppercase mt-3 mb-4">
                                Add New Template
                              </h4>
                              <hr/>
                            <div className="flex flex-1 overfl">
                              <div className="w-full p-2">
                                <div className="pt-4">
                                  <div className="simplebar-container overflow-auto" >
                                      <form onSubmit={AddTemplateSubmitHandler}>
                                        <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                          <div className="xl:col-span-6 md:col-span-6">
                                            <label htmlFor="template_name" className="form-label font-semibold">
                                              Template Name
                                            </label>
                                            <div className="">
                                              <input
                                                type="text"
                                                className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                name="template_name"
                                                id="template_name"
                                                onChange={(e) => setTemplateName(e.target.value)}
                                                value={templateName}
                                                placeholder="Template Name"
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="xl:col-span-6 md:col-span-6">
                                            <label htmlFor="template_category" className="form-label font-semibold">
                                              Template Category
                                            </label>
                                            <select
                                              name="template_category"
                                              className="form-select form-input w-full py-2 px-3 h-10 bg-transparent  dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                              onChange={(e) => setTemplateCategory(e.target.value)}
                                              value={templateCategory}
                                            >
                                              <option value="" selected>
                                                Category...
                                              </option>
                                              {templateCategoryList&&templateCategoryList.map((input, index) => (
                                              <option  key={index} value={input}>{input}</option>
                                              ))}
                                            </select>
                                          </div> */}
                                          <div className="w-full col-span-12 my-4">
                                            <hr className="border-t border-gray-300 dark:border-gray-700" />
                                          </div>
                                          </div>
                                          <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                            <div className="xl:col-span-6 md:col-span-6">
                                              <label htmlFor="media" className="form-label font-medium">
                                                Header:
                                              </label>
                                              <select
                                                name="media"
                                                className="form-select form-input mb-3 w-full py-2 px-3 h-10 bg-transparent  dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                onChange={(e) => setHeaderMediaType(e.target.value)}
                                                value={headerMediaType}
                                              >
                                                <option value="none" selected>
                                                  None
                                                </option>
                                                <option value="text" >
                                                  Text
                                                </option>
                                                <option value="document" >
                                                  Document
                                                </option>
                                                <option value="image" >
                                                  Image
                                                </option>
                                                
                                              </select>
                                            </div>
                                          </div>
                                          {/* {headerMediaType=="media"&&
                                          <>
                                          <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                            <div className="xl:col-span-12 md:col-span-12 mb-3">
                                              <label htmlFor="document_link" className="form-label font-medium">
                                                Document Link:
                                              </label>
                                              <div className="">
                                                <input
                                                  type="text"
                                                  className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                  name="document_link"
                                                  id="document_link"
                                                  onChange={(e) => setHeaderDocumentLink(e.target.value)}
                                                  value={headerDocumentLink}
                                                  placeholder="https://drive.google.com/uc?export=download&id=FILE_ID"
                                                />
                                              </div>
                                            </div>
                                          </div>  
                                          <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                            <div className="xl:col-span-12 md:col-span-12 mb-3">
                                              <label htmlFor="document_link" className="form-label font-medium">
                                                Document Name:
                                              </label>
                                              <div className="">
                                                <input
                                                  type="text"
                                                  className="form-input w-full mb-3  py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                  name="document_name"
                                                  id="document_name"
                                                  onChange={(e) => setHeaderDocumentName(e.target.value)}
                                                  value={headerDocumentName}
                                                  placeholder="example.pdf"
                                                />
                                              </div>
                                            </div>
                                          </div>  
                                          </>
                                          } */}
                                          {headerMediaType=="text"&&
                                          <div className="xl:col-span-12 md:col-span-12">
                                            <textarea
                                              ref={textareaRefHeader}
                                              className="w-full p-4  mb-2 bg-gray-100 dark:bg-gray-800 outline-none resize-none  dark:text-white rounded-md  overflow-hidden border focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 "
                                              name="header_content"
                                              id="header_content"
                                              placeholder="Template Header"
                                              onChange={handleAddTemplateHeaderContentChange}
                                              onInput={adjustTextareaHeightHeader}
                                              value={addTemplateHeaderContent}
                                            />
                                          </div>}
                                          <div className="xl:col-span-12 md:col-span-12">
                                            <label className="font-semibold pb-3">Body:</label>
                                            <p dangerouslySetInnerHTML={{ __html: 'Use variables like {{customer_name}} to personalize your messages' }} className=" text-gray-500 font-medium"/>
                                            <textarea
                                              ref={textareaRef}
                                              className="w-full p-4 mt-2 mb-2 bg-gray-100 outline-none   dark:bg-gray-800 dark:text-white rounded-md border  focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 resize-none overflow-hidden min-h-[100px]"                                          
                                              name="body_content"
                                              id="body_content"
                                              placeholder="Template Body"
                                              onChange={handleAddTemplateBodyContentChange}
                                              onInput={adjustTextareaHeight}
                                              value={addTemplateBodyContent}
                                            />
                                            </div>
                                        
                                        <div className="flex flex-col items-end space-y-4">
                                          {/* <div className="flex justify-end">
                                            <button 
                                              onClick={handleAddButtonClick} 
                                              disabled={isAdding}
                                              className={`py-2 px-4 rounded-lg font-semibold  text-black border-2  border-black  ${
                                                isAdding ? 'bg-gray-200 cursor-not-allowed' : 'bg-gray-300 hover:bg-slate-500 hover:border-gray-700 '
                                              } transition-colors duration-300`}
                                      
                                            >
                                              Add Button
                                            </button>
                                          </div> */}
                                        
                                          {isAdding && (
                                          <div className="grid gap-5 grid-col-1  md:grid-cols-12">
                                            <div className="xl:col-span-6 md:col-span-6">
                                              <label htmlFor="website_name" className="">
                                                Website Name
                                              </label>
                                              <input
                                                type="text"
                                                id="website_name"
                                                name="website_name"
                                                value={websiteName}
                                                onChange={(e) => setWebsiteName(e.target.value)}
                                                className="form-input w-full py-2 px-3 h-10 mb-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                placeholder="Enter website name"
                                              />
                                            </div>
                                
                                            <div className="xl:col-span-6 md:col-span-6">
                                              <label htmlFor="website_url" className="">
                                                Website URL
                                              </label>
                                              <input
                                                type="text"
                                                id="website_url"
                                                name="website_url"
                                                value={websiteURL}
                                                onChange={(e) => setWebsiteURL(e.target.value)}
                                                className="form-input w-full py-2 px-3 mb-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                                                placeholder="Enter website URL"
                                              />
                                          </div>
                                        </div>
                                          )}  
                                          <div className="flex justify-start">                              
                                        <button
                                          type="submit"
                                              className="py-2 px-5 font-semibold tracking-wide border border-indigo-600 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700 transition-colors duration-300"                                  >
                                              Add Template
                                        </button>
                                          </div>
                                        </div>
                                      </form>
                                  </div>
                               </div>
                              </div>
                              <div className="flex-none w-2/5 p-4 ">
                                <div className="mt-8">
                                  <div className=" text-gray-800 text-xl flex items-center justify-center h-32 mt-4 mb-4">Preview</div>
                                  <div className="">
                                    <div className="chat-container rounded-3xl overflow-hidden ">
                                      <div className="chat">
                                        <div className="chat-header">
                                          <div className="profile">
                                            <div className="left">
                                              <img src="static/assets/img/arrow.png" alt="Arrow" className="arrow" />
                                              {/* <img src="static/static/assets/img/pp.png" alt="Profile" className="pp" /> */}
                                            </div>
                                            <div className="right">
                                              <img src="static/assets/img/video.png" alt="Video Call" className="icon" />
                                              <img src="static/assets/img/phone.png" alt="Phone Call" className="phone" />
                                              <img src="static/assets/img/more.png" alt="More Options" className="icon" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chat-box " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/static/assets/img/bg.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                                          <div className="chat-l">
                                            <div className="mess">
                                              <div className="mess-content">
                                              {addTemplateHeaderContent && (
                                                <>
                                                  <pre>{addTemplateHeaderContent}</pre>
                                                  {/* <br /> */}
                                                </>
                                              )}
                                              <pre>
                                              {addTemplateBodyContent}
                                              </pre>
                                              <div className="check">
                                                <span>4:00 PM</span>
                                              </div>
                                              </div>
                                              <div className="p-2">
                                              {isAdding&&
                                              <div>
                                              <hr className="w-full border-0 border-t border-gray-200" style={{ margin: '4px 0 0 0' }} />
                                              <div className="flex justify-center items-center text-blue-600 p-2"> 
                                              <span class="mdi mdi-open-in-new"></span>
                                                <div className=" ms-1">Visit website </div>
                                              </div>
                                              </div>}
                                              </div>
                                            </div>
                                            <div className="sp"></div>
                                          </div>

                                          

                                        
                                        </div>

                                        <div className="chat-footer">
                                          <img src="static/assets/img/emo.png" alt="Emoji" className="emo" />
                                          <textarea placeholder="Message" readOnly></textarea>
                                          <div className="icons">
                                            <img src="static/assets/img/attach file.png" alt="Attach File" />
                                            <img src="static/assets/img/camera.png" alt="Camera" />
                                          </div>
                                          <img src="static/assets/img/mic.png" alt="Mic" className="mic" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )):null}
              </div>
            </div>
          </div>

          <Footer />
        </main>
      </div>
    </>
  );
}

export default Templates;
