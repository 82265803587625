import { createSlice } from "@reduxjs/toolkit";

const userInfoFromStorage = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null;

const token = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null;

const initialState = {
    userInfo: userInfoFromStorage,
    token: token
};

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        storeUserInfo(state, action) {
            state.loading = false;
            state.userInfo = action.payload;
            sessionStorage.setItem('userInfo', JSON.stringify(action.payload));
        },
        storeToken(state, action) {
            state.loading = false;
            state.token = action.payload;
            sessionStorage.setItem('token', JSON.stringify(action.payload));
        },
    },
});

export default userInfoSlice.reducer;
export const { storeUserInfo, storeToken } = userInfoSlice.actions;
